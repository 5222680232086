export const Catalog = () => {

    return (

        <div className='catalog__container' >

            <div className="catalog__id-target" id="catalog" />

            <div className='catalog__content-container utils__fade-in'>

                <h1 ><span>Alquiler</span> y <span>venta</span> de andamios</h1>

                <div className='catalog__list'>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/andamio.webp`}
                            alt='Logo 1'
                        />

                        <h2>Andamios</h2>

                    </div>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/tablones.webp`}
                            alt='Logo 2'
                        />

                        <h2>Tablones</h2>

                    </div>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/escaleras-internas.webp`}
                            alt='Logo 3'
                        />

                        <h2>Escaleras<br />internas</h2>

                    </div>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/ruedas.webp`}
                            alt='Logo 4'
                        />

                        <h2>Ruedas</h2>

                    </div>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/tornillones.webp`}
                            alt='Logo 5'
                        />

                        <h2>Tornillones</h2>

                    </div>

                    <div className='catalog__card'>

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/seguridad.webp`}
                            alt='Logo 6'
                        />

                        <h2>Arnés de<br />seguridad</h2>

                    </div>

                </div>

            </div>


        </div>

    )
}
