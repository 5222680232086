import { useLogEvent } from '../hooks/useLogEvent';

export const Social = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <div className='social__container'>

            <div className="social__id-target" id="social" />

            <div className='social__content-container utils__fade-in'>

                <h1>Social</h1>

                <h2>Nuestras redes</h2>

                <div className='social__buttons-container'>

                    <a
                        className='social__button'
                        href="https://www.instagram.com/sr.andamios/"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_instagram');
                        }}
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/instagram.webp`}
                            alt='Logo 1'
                        />

                        <h3>Instagram</h3>

                    </a>

                    <a
                        className='social__button'
                        href="https://www.facebook.com/profile.php?id=61550579933261"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_facebook');
                        }}
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/facebook.webp`}
                            alt='Logo 2'
                        />

                        <h3>Facebook</h3>

                    </a>

                    <a
                        className='social__button'
                        href="mailto:srandamios.presupuestos@gmail.com"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_correo');
                        }}
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/correo.webp`}
                            alt='Logo 3'
                        />

                        <h3>Correo</h3>

                    </a>

                </div>

            </div>


        </div>

    )
}
