import { Catalog } from "../components/Catalog"
import { Counter } from "../components/Counter"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { Tips } from "../components/Tips"
import { Quotator } from "../components/Quotator"
import { WsFloatButton } from "../components/WsFloatButton"
import { Social } from "../components/Social"
import { Footer } from "../components/Footer"
import { Rights } from "../components/Rights"

export const HomeScreen = () => {

    return (

        <div className='home__main-content'>

            <WsFloatButton />

            <Header />

            <Introduction />

            <Tips
                firstLogo="atencion-al-cliente.webp"
                firstTitle="Atención de calidad"
                secondLogo="ahorrar-dinero.webp"
                secondTitle="Los mejores precios"
                thirdLogo="envios.webp"
                thirdTitle="Entregas en el dia"
            />

            <Counter />

            <Catalog />

            <Tips
                firstLogo="cotizador.webp"
                firstTitle="Cotizá"
                firstDescription={<h2>Armá el presupuesto a tu gusto en nuestro cotizador</h2>}
                secondLogo="chat.webp"
                secondTitle="Reservá"
                secondDescription={<h2>Coordiná la fecha y hora de entrega y la forma de pago</h2>}
                thirdLogo="envios.webp"
                thirdTitle="Recibí"
                thirdDescription={<h2>Te llevamos todo lo necesario para que puedas trabajar de la mejor manera</h2>}
            />

            <Quotator />

            <Social />

            <Footer />

            <Rights />

        </div>

    )
}
