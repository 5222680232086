import { useEffect, useState } from "react"
import { useLogEvent } from "../hooks/useLogEvent";

export const Quotator = () => {

    const { handleLogEvent } = useLogEvent()

    const [form, setForm] = useState({
        scaffoldCount: -1,
        boardsCount: -1,
        daysCount: -1,
        price: '',
        customScaffold: false,
        customBoards: false,
        customDays: false
    })

    const getPrices = (days: number) => {

        let boardsPrice = 0;

        let pricePerDay = 0;

        switch (days) {
            case 3:
                pricePerDay = 2200
                break;
            case 7:
                pricePerDay = 1100
                break;
            case 15:
                pricePerDay = 900
                break;
            case 30:
                pricePerDay = 900
                break;
        }

        if (form.scaffoldCount >= 3) {

            if (form.scaffoldCount <= form.boardsCount) {

                let boardsAdditional = 1 + (form.boardsCount - form.scaffoldCount);

                boardsPrice += 6000 * boardsAdditional
            }
            // Nuevos precios

        }

        return `${days} días ($${new Intl.NumberFormat('de-DE').format((pricePerDay * form.scaffoldCount * days) + boardsPrice)})`
    }

    const handleQuote = () => {

        const price = getPrices(form.daysCount).split(' ')[2].replace('(', '').replace(')', '');

        let message = `https://wa.me/+5491173659373?text=Hola SR Andamios! Quiero pedirles una cotización de: %0a %0a - *${form.scaffoldCount}* cuerpos de andamios %0a - *${form.boardsCount}* tablones de chapa %0a - *${form.daysCount}* dias de alquiler ${!form.customDays ? '%0a %0a  Precio cotizado sin envio: *' + price + '*' : ''} %0a %0a Aguardo a su respuesta.  %0a ¡Muchas gracias!`;

        handleLogEvent('Click_boton_whatsapp_cotizacion');

        window.open(message, 'blank')
    }

    useEffect(() => {
        console.log(form);

    }, [form])

    return (

        <div className='quotator__container'>

            <div className="quotator__id-target" id="quotator" />

            <div className='quotator__content-container utils__fade-in'>

                <h1><span>Cotizá</span> en segundos</h1>

                <h2>Elegí lo que necesites y comunicate con nosotros para obtener tu presupuesto.</h2>

                <div className='quotator__form'>

                    <div className='quotator__custom-input-container'>

                        <div className='quotator__input-container'>

                            <h2 id="scaffold-label">Cantidad de cuerpos de andamio *</h2>

                            <select
                                name="scaffold"
                                id="scaffold-select"
                                aria-labelledby="scaffold-label"

                                onChange={(e) => {

                                    if (e.target.value === 'Indicar andamios') {
                                        setForm({ ...form, customScaffold: true, scaffoldCount: -1, price: '' })
                                    } else {

                                        if (parseInt(e.target.value) === -1) {
                                            setForm({
                                                ...form,
                                                customScaffold: false,
                                                scaffoldCount: -1,
                                            })
                                        } else {
                                            setForm({ ...form, customScaffold: false, scaffoldCount: parseInt(e.target.value) })
                                        }
                                    }

                                }}
                            >
                                <option value={-1}>- Seleccione una opción -</option>
                                <option value={1}>1 cuerpo</option>
                                <option value={2}>2 cuerpos</option>
                                <option value={3}>3 cuerpos</option>
                                <option value={4}>4 cuerpos</option>
                                <option value={5}>5 cuerpos</option>
                                <option value={6}>6 cuerpos</option>
                                <option value={7}>7 cuerpos</option>
                                <option value={8}>8 cuerpos</option>
                                <option value={9}>9 cuerpos</option>
                                <option value={10}>10 cuerpos</option>
                                <option value={11}>11 cuerpos</option>
                                <option value={12}>12 cuerpos</option>
                                <option value={13}>13 cuerpos</option>
                                <option value={14}>14 cuerpos</option>
                                <option value={15}>15 cuerpos</option>
                                <option value={16}>16 cuerpos</option>
                                <option value={17}>17 cuerpos</option>
                                <option value={18}>18 cuerpos</option>
                                <option value={19}>19 cuerpos</option>
                                <option value={20}>20 cuerpos</option>
                                <option value={21}>21 cuerpos</option>
                                <option value={22}>22 cuerpos</option>
                                <option value={23}>23 cuerpos</option>
                                <option value={24}>24 cuerpos</option>
                                <option value={25}>25 cuerpos</option>
                                <option value={26}>26 cuerpos</option>
                                <option value={27}>27 cuerpos</option>
                                <option value={28}>28 cuerpos</option>
                                <option value={29}>29 cuerpos</option>
                                <option value={30}>30 cuerpos</option>
                                <option value={31}>31 cuerpos</option>
                                <option value={32}>32 cuerpos</option>
                                <option value={33}>33 cuerpos</option>
                                <option value={34}>34 cuerpos</option>
                                <option value={35}>35 cuerpos</option>
                                <option value={36}>36 cuerpos</option>
                                <option value={37}>37 cuerpos</option>
                                <option value={38}>38 cuerpos</option>
                                <option value={39}>39 cuerpos</option>
                                <option value={40}>40 cuerpos</option>
                                <option value={41}>41 cuerpos</option>
                                <option value={42}>42 cuerpos</option>
                                <option value={43}>43 cuerpos</option>
                                <option value={44}>44 cuerpos</option>
                                <option value={45}>45 cuerpos</option>
                                <option value={46}>46 cuerpos</option>
                                <option value={47}>47 cuerpos</option>
                                <option value={48}>48 cuerpos</option>
                                <option value={49}>49 cuerpos</option>
                                <option value={50}>50 cuerpos</option>
                                <option value="Indicar andamios">Indicar andamios</option>
                            </select>

                        </div>

                        {form.customScaffold && (<div className='quotator__input-container'>

                            <h2 id="scaffold-label-count">Indicar cantidad de andamios *</h2>

                            <input
                                aria-labelledby="scaffold-label-count"
                                className='quotator__input-custom'
                                value={form.scaffoldCount === -1 ? '' : form.scaffoldCount}
                                placeholder="Ingrese un valor"
                                onChange={(e) => {

                                    if (e.target.value === '') {
                                        setForm({ ...form, scaffoldCount: 1 })
                                    } else {
                                        setForm({ ...form, scaffoldCount: parseInt(e.target.value) })
                                    }

                                }}
                                type="number"
                                minLength={1}
                                min={1}
                            />

                        </div>)}

                    </div>

                    <div className='quotator__custom-input-container'>

                        <div className='quotator__input-container'>

                            <h2 id="boards-label">Cantidad de tablones de chapa *</h2>

                            <select
                                aria-labelledby="boards-label"
                                name="boards"
                                id="boards-select"
                                disabled={form.scaffoldCount < 0}
                                onChange={(e) => {

                                    if (e.target.value === 'Indicar tablones') {
                                        setForm({ ...form, customBoards: true, boardsCount: -1, price: '' })
                                    } else {
                                        if (parseInt(e.target.value) === -1) {
                                            setForm({
                                                ...form,
                                                customBoards: false,
                                                boardsCount: -1,
                                            })
                                        } else {
                                            setForm({ ...form, customBoards: false, boardsCount: parseInt(e.target.value) })
                                        }
                                    }

                                }}
                            >
                                <option value={-1}>{form.scaffoldCount < 0 ? "Ingrese la cantidad de andamios" : "- Seleccione una opción -"}</option>
                                <option value={0}>0 tablones</option>
                                <option value={1}>1 tablón</option>
                                <option value={2}>2 tablones</option>
                                <option value={3}>3 tablones</option>
                                <option value={4}>4 tablones</option>
                                <option value={5}>5 tablones</option>
                                <option value={6}>6 tablones</option>
                                <option value={7}>7 tablones</option>
                                <option value={8}>8 tablones</option>
                                <option value={9}>9 tablones</option>
                                <option value={10}>10 tablones</option>
                                <option value={11}>11 tablones</option>
                                <option value={12}>12 tablones</option>
                                <option value={13}>13 tablones</option>
                                <option value={14}>14 tablones</option>
                                <option value={15}>15 tablones</option>
                                <option value={16}>16 tablones</option>
                                <option value={17}>17 tablones</option>
                                <option value={18}>18 tablones</option>
                                <option value={19}>19 tablones</option>
                                <option value={20}>20 tablones</option>
                                <option value={21}>21 tablones</option>
                                <option value={22}>22 tablones</option>
                                <option value={23}>23 tablones</option>
                                <option value={24}>24 tablones</option>
                                <option value={25}>25 tablones</option>
                                <option value={26}>26 tablones</option>
                                <option value={27}>27 tablones</option>
                                <option value={28}>28 tablones</option>
                                <option value={29}>29 tablones</option>
                                <option value={30}>30 tablones</option>
                                <option value={31}>31 tablones</option>
                                <option value={32}>32 tablones</option>
                                <option value={33}>33 tablones</option>
                                <option value={34}>34 tablones</option>
                                <option value={35}>35 tablones</option>
                                <option value={36}>36 tablones</option>
                                <option value={37}>37 tablones</option>
                                <option value={38}>38 tablones</option>
                                <option value={39}>39 tablones</option>
                                <option value={40}>40 tablones</option>
                                <option value={41}>41 tablones</option>
                                <option value={42}>42 tablones</option>
                                <option value={43}>43 tablones</option>
                                <option value={44}>44 tablones</option>
                                <option value={45}>45 tablones</option>
                                <option value={46}>46 tablones</option>
                                <option value={47}>47 tablones</option>
                                <option value={48}>48 tablones</option>
                                <option value={49}>49 tablones</option>
                                <option value={50}>50 tablones</option>
                                <option value="Indicar tablones">Indicar tablones</option>
                            </select>

                        </div>

                        {(form.customBoards && form.scaffoldCount > 0) && (<div className='quotator__input-container'>

                            <h2 id="boards-label-count">Indicar cantidad de tablones *</h2>

                            <input
                                aria-labelledby="boards-label-count"
                                className='quotator__input-custom'
                                value={form.boardsCount === -1 ? '' : form.boardsCount}
                                placeholder="Ingrese un valor"
                                onChange={(e) => {

                                    if (e.target.value === '') {
                                        setForm({ ...form, boardsCount: 1 })
                                    } else {
                                        setForm({ ...form, boardsCount: parseInt(e.target.value) })
                                    }

                                }}
                                type="number"
                                minLength={1}
                                min={1}
                            />

                        </div>)}

                    </div>

                    <div className='quotator__custom-input-container'>

                        <div className='quotator__input-container'>

                            <h2 id="days-label">Cantidad de dias que quiera alquilar *</h2>

                            <select
                                aria-labelledby="days-label"
                                name="days"
                                id="days-select"
                                disabled={form.boardsCount < 0 && form.scaffoldCount < 0}
                                onChange={(e) => {

                                    if (e.target.value === 'Indicar días') {
                                        setForm({ ...form, customDays: true, daysCount: -1, price: '' })
                                    } else {
                                        if (parseInt(e.target.value) === -1) {
                                            setForm({
                                                ...form,
                                                customDays: false,
                                                daysCount: -1,
                                                price: ''
                                            })
                                        } else {
                                            setForm({
                                                ...form,
                                                customDays: false,
                                                daysCount: parseInt(e.target.value.split(' ')[0]),
                                                price: e.target.value.split(' ')[2].replace('(', '').replace(')', '')
                                            })

                                        }
                                    }

                                }}
                            >
                                <option value={-1}>{form.boardsCount < 0 ? "Ingrese la cantidad de tablones" : "- Seleccione una opción -"}</option>
                                <option value={getPrices(3)}>{getPrices(3)}</option>
                                <option value={getPrices(7)}>{getPrices(7)}</option>
                                <option value={getPrices(15)}>{getPrices(15)}</option>
                                <option value={getPrices(30)}>{getPrices(30)}</option>
                                <option value="Indicar días">Indicar días</option>
                            </select>

                        </div>

                        {(form.customDays && form.boardsCount >= 0 && form.scaffoldCount > 0) && (<div className='quotator__input-container'>

                            <h2 id="days-label-count">Indicar cantidad de días *</h2>

                            <input
                                aria-labelledby="days-label-count"
                                className='quotator__input-custom'
                                value={form.daysCount === -1 ? '' : form.daysCount}
                                placeholder="Ingrese un valor"
                                onChange={(e) => {

                                    if (e.target.value === '') {
                                        setForm({ ...form, daysCount: 1 })
                                    } else {
                                        setForm({ ...form, daysCount: parseInt(e.target.value) })
                                    }

                                }}
                                type="number"
                                minLength={1}
                                min={1}
                            />

                        </div>)}

                    </div>

                    <h3>
                        Si desea agregar algun elemento adicional de nuestro catálogo consultenos vía WhatsApp junto con su cotización.
                        <br />
                        <br />
                        Los precios son aproximados.
                    </h3>

                    <button
                        onClick={handleQuote}
                        disabled={
                            form.scaffoldCount < 0
                            || form.boardsCount < 0
                            || form.daysCount <= 0
                            || (form.scaffoldCount === 0 && form.boardsCount === 0)
                        }
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/whatsapp.webp`}
                            alt='WhatsApp Logo'
                        />

                        <h3>Solicitar presupuesto</h3>

                    </button>

                </div>

            </div>


        </div>

    )
}
