
export const Counter = () => {

    return (

        <div className='counter__container'>

            <div className='counter__content-container utils__fade-in'>

                <div className='counter__info'>

                    <h1>+1546</h1>

                    <h2>+ANDAMIOS ACTIVOS POR<br />TODO CABA Y GRAN BS AS</h2>

                </div>


                <div className='counter__info'>

                    <h1>+904</h1>

                    <h2>CLIENTES SATISFECHOS<br />CONFIAN EN NOSOTROS</h2>

                </div>

                <div className='counter__info'>

                    <h1>+5</h1>

                    <h2 >+AÑOS EN<br />EL RUBRO</h2>

                </div>

            </div>

            <div className='counter__shadow' />

        </div>

    )
}
