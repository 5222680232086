import { useEffect, useState } from "react";

export const AppLogoComponent = () => {

    const [show, handleShow] = useState(false);

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            handleShow(true);
        } else {
            handleShow(false);
        }

    }

    useEffect(() => {

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    return (

        <div className={`logo__container ${show && "logo__animated-container"}`}>

            <img
                loading="lazy"
                className='logo__logoImage'
                src={`${process.env.REACT_APP_CDN}/bigote.webp`}
                alt='logo'
            />

            <h1 className='logo__logoName'>Sr<span> Andamios</span></h1>

        </div>

    )
}
