import { useLogEvent } from "../hooks/useLogEvent";

export const WsFloatButton = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <a
            className='button__floatButton  utils__fade-in'
            target="_blank"
            href="https://wa.me/+5491173659373?text=Hola SR Andamios! Quiero pedirles una cotización."
            rel="noreferrer"
            onClick={() => {
                handleLogEvent('Click_boton_whatsapp_flotante');
            }}
        >

            <img
                loading="lazy"
                src={`${process.env.REACT_APP_CDN}/whatsapp.webp`}
                alt='Logo WhatsApp'
            />

        </a>

    )
}
