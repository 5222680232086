
interface Props {
    firstLogo: string;
    firstTitle: string;
    firstDescription?: JSX.Element;
    secondLogo: string;
    secondTitle: string;
    secondDescription?: JSX.Element;
    thirdLogo: string;
    thirdTitle: string;
    thirdDescription?: JSX.Element;
}

export const Tips = ({
    firstLogo, firstTitle, firstDescription,
    secondLogo, secondTitle, secondDescription,
    thirdLogo, thirdTitle, thirdDescription
}: Props) => {

    return (

        <div className='tips__container'>

            <div className='tips__content-container  utils__fade-in'>

                <div className='tips__info' style={{ fontSize: firstDescription ? 16 : 14 }}>

                    <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_CDN}/${firstLogo}`}
                        alt='Logo 1'
                    />

                    <div>

                        <h1>{firstTitle}</h1>

                        {firstDescription ?? firstDescription}

                    </div>


                </div>


                <div className='tips__info' style={{ fontSize: secondDescription ? 16 : 14 }}>

                    <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_CDN}/${secondLogo}`}
                        alt='Logo 2'
                    />

                    <div>

                        <h1>{secondTitle}</h1>

                        {secondDescription ?? secondDescription}

                    </div>


                </div>

                <div className='tips__info' style={{ fontSize: thirdDescription ? 16 : 14 }}>

                    <img
                        loading="lazy"
                        src={`${process.env.REACT_APP_CDN}/${thirdLogo}`}
                        alt='Logo 3'
                    />

                    <div>

                        <h1>{thirdTitle}</h1>

                        {thirdDescription ?? thirdDescription}

                    </div>


                </div>

            </div>

        </div>

    )
}
