import { useLogEvent } from "../hooks/useLogEvent"

export const Introduction = () => {

    const { handleLogEvent } = useLogEvent()

    return (


        <div className='introduction__container utils__fade-in' id="home">

            <div className='introduction__header-null-space-container' />

            <div className='introduction__content-container'>

                <div className='introduction__info' >

                    <div>

                        <h1>Alquiler y<br />venta de<br /><span>Andamios</span></h1>

                        <h2><span>+5 años</span> brindando un servicio de<br /><span>primera calidad</span> para todos nuestros<br />clientes de manera <span>simple</span> y <span>rápida</span>.</h2>

                        <a
                            target="_blank"
                            href="https://wa.me/+5491173659373?text=Hola SR Andamios! Quiero pedirles una cotización."
                            rel="noreferrer"
                            onClick={() => {
                                handleLogEvent('Click_boton_whatsapp_inicio');
                            }}
                        >

                            <img
                                loading="lazy"
                                src={`${process.env.REACT_APP_CDN}/whatsapp.webp`}
                                alt='WhatsApp Logo'
                            />

                            <h3>1173659373</h3>

                        </a>

                    </div>

                </div>

                <div
                    className='introduction__image'
                    style={{
                        background: `${process.env.REACT_APP_CDN}/andamios-intro.webp center no-repeat`,
                        backgroundSize: 'cover'
                    }}
                >

                    <div className="introduction__shadow-first" />

                    <div className="introduction__shadow-second" />

                </div>

            </div>

        </div>

    )
}
