import { useEffect, useState } from "react";
import { useAnimations } from "../effects/Effects";

export const NavButtons = () => {

    const [show, handleShow] = useState(false);

    const {
        handleClickGoHome,
        handleClickGoCatalog,
        handleClickGoQuotator,
        handleClickGoSocial
    } = useAnimations()

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            handleShow(true);
        } else {
            handleShow(false);
        }

    }

    useEffect(() => {

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);



    return (

        <div className={`navbar__container ${show && "navbar__animated-container"}`}>

            <nav className="navbar__buttons">

                <button onClick={handleClickGoHome}>Inicio</button>

                <button onClick={handleClickGoCatalog}>Catalogo</button>

                <button onClick={handleClickGoQuotator}>Cotizador</button>

                <button onClick={handleClickGoSocial}>Contacto</button>

            </nav>

        </div>

    )
}
