
export const useAnimations = () => {

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoCatalog = () => {
        const element = document.getElementById('catalog');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoQuotator = () => {
        const element = document.getElementById('quotator');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoSocial = () => {
        const element = document.getElementById('social');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return {
        handleClickGoHome,
        handleClickGoCatalog,
        handleClickGoQuotator,
        handleClickGoSocial,
    }

}