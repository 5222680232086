import { useEffect, useState } from "react";
import { AppLogoComponent } from "./AppLogoComponent"
import { NavButtons } from "./NavButtons";

export const Header = () => {

    const [show, handleShow] = useState(false);

    const transitionNavBar = () => {

        if (window.scrollY > 50) {
            handleShow(true);
        } else {
            handleShow(false);
        }

    }

    useEffect(() => {

        window.addEventListener('scroll', transitionNavBar);

        return () => window.removeEventListener('scroll', transitionNavBar);

    }, []);

    return (

        <div className={`header__container ${show && "header__animated-container"}`}>

            <AppLogoComponent />

            <NavButtons />

        </div>

    )
}
