import { useLogEvent } from "../hooks/useLogEvent"
import { HomeFloatButton } from "./HomeFloatButton"

export const Footer = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <div className='footer__container'>

            <div className='footer__content-container utils__fade-in'>

                <div className='footer__buttons-container'>

                    <a className='footer__button'
                        href="https://goo.gl/maps/oadqyUkam9WP7Wms9"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_ubicacion');
                        }}
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/ubicacion.webp`}
                            alt='Logo 1'
                        />

                        <h3>Av. Pres. Arturo Umberto Illia 5448, Los Polvorines, Buenos Aires</h3>

                    </a>

                    <a
                        className='footer__button'
                        href="tel:+5491173659373"
                        onClick={() => {
                            handleLogEvent('Click_boton_telefono');
                        }}
                    >

                        <img
                            loading="lazy"
                            src={`${process.env.REACT_APP_CDN}/telefono.webp`}
                            alt='Logo 2'
                        />

                        <h3>+54 011 7365-9373</h3>

                    </a>

                </div>

                <div>
                    <HomeFloatButton />
                </div>

            </div>


        </div >

    )
}
