import { useAnimations } from "../effects/Effects"
import { useLogEvent } from "../hooks/useLogEvent"

export const HomeFloatButton = () => {

    const { handleClickGoHome } = useAnimations()

    const { handleLogEvent } = useLogEvent()

    return (

        <button className='button__homeFloatButton' onClick={() => {
            handleClickGoHome()
            handleLogEvent('click_boton_volver_al_inicio')
        }}>

            <img
                src={`${process.env.REACT_APP_CDN}/flecha-arriba.webp`}
                alt='Logo Go Home'
            />

        </button>

    )
}
